<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'已有车辆信息'" :total="total"/>
      <CommonHeaders
        :formConfig="formConfig"
        :formModel="formModel"
        :buttons="buttons"
        @button-click="handleButtonClick"
      ></CommonHeaders>

    </div>
    
    <div class="commonControl-body">
     
      <common-table
        :tableData="tableData"
        :tableButton="tableButton"
        :tableLabel="tableLabel"
        @edit="editFirstLevel"
      ></common-table>
      <div class="layoutBorder">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>

    </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑产品" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="产品id" label-width="120px">
          <el-input v-model="formEdit.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="产品系列" label-width="120px">
          <el-input v-model="formEdit.product_series" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="产品分类" label-width="120px">
        <el-select v-model="optionValue" placeholder="请选择">
          <el-option
            v-for="item in value"
            :key="item.id"
            :label="item.product_type_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="产品名" label-width="120px">
          <el-input v-model="formEdit.product_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增产品类型" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="产品类型名字" label-width="120px">
          <el-input v-model="formAdd.product_type_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  carinfo_query
} from "@/api/manage/operation/car/car_library.js";
import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
import CommonHeaders from "@/components/shibo_Common/CommonHeaders.vue";

export default {
  name: "start",
  data() {
    return {
      formData:{},
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
        is_delete:false
      },
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "id",
          minWidth:"20%"
          // position:"right"
        },
        {
          prop: "license_plate",
          label: "车辆牌号",
          minWidth:"20%"
        },
        {
          prop: "monthly",
          label: "月保车辆",
          minWidth:"20%"
          
        },
      ],
      tableButton: {
        width:300,
        data:[
          {
            name: "查看详情",
            type: "info",
            size: "small",
          }
        ]
      },
      value: null,
      optionValue: null,
      total: null,
      formConfig: [
        {
          label: '车牌号',
          prop: 'keyword',
          component: 'el-input',
          placeholder: '请输入车牌号'
        },
        {
          label: '车辆状态',
          prop: 'is_delete',
          component: 'el-select',
          placeholder: '请选择状态',
          options: [
            {label:'正常',value:'false'},
            {label:'异常',value:'true'}
          ]
        }
      ],
      formModel: {},
      buttons: [
        { label: "查询", type: "primary",action:'search' },
        { label: "新增车辆信息", type: "primary",action:'create' },
      ]
    };
  },
  components: {
    CommonTable,
    CommonHeaders
  },
  created() {
    this.getFirstLevel();
    this.initOption()
  },
  methods: {
    initOption() {
      carinfo_query({}).then((res) => {
        if (res.code == 200) {
          this.value = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    editFirstLevel(row, methods) {
      if (methods == "查看详情") {
        this.gotoUser(row);
      }
    },
    gotoUser(row) {
      this.$router.push({ name: "product_Info", query: row });
    },
    editUser(row) {
      this.formEdit = row;
      // console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
      // row;
      // this.$router.push({ name: "productLibraryInfo", query: { id: row.id } });
    },
    // delUser(row) {
    //   let ids = row.id;
    //   console.log({ group_id: ids });
    //   this.$confirm("是否确定删除", "确认信息", {
    //     distinguishCancelAndClose: true,
    //     confirmButtonText: "删除",
    //     cancelButtonText: "放弃删除",
    //   })
    //     .then(() => {
    //       product_set({
    //         product_id: ids, //产品ID
    //         is_delete: true,
    //       }).then((res) => {
    //         if (res.code == 200) {
    //           this.$message.success("成功删除");
    //           window.location.reload();
    //         } else {
    //           this.$message.info(res.msg);
    //         }
    //       });
    //     })
    //     .catch((action) => {
    //       this.$message({
    //         type: "info",
    //         message: action === "cancel" ? "放弃删除" : "停留在当前",
    //       });
    //     });
    // },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      carinfo_query(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      console.log(this.query);
      carinfo_query(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data.result;
      });
    },
    // createFirstLevel() {
    //   product_classify_create(this.formAdd).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success("成功添加");
    //       window.location.reload();
    //     } else {
    //       this.$message.info(res.msg);
    //     }
    //   });
    //   this.dialogFormVisibleAdd = false;
    // },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    // updateFirstLevel() {
    //   this.formEdit["product_id"] = this.formEdit["id"];
    //   Reflect.deleteProperty(this.formEdit,"id")
    //   this.formEdit["product_type"]=this.optionValue
    //   console.log(this.formEdit);
    //   product_edit(this.formEdit).then((res) => {
    //     if (res.code == 200) {
    //       this.$message.success("成功修改");
    //       this.getFirstLevel()
    //     } else {
    //       this.$message.info(res.msg);
    //     }
    //   });
    //   this.dialogFormVisibleEdit = false;
    // },
    getFirstLevel() {
      carinfo_query(this.query).then((res) => {
        this.total = res.data.result.length;
        this.tableData = res.data.result;
      });
    },
    handleFormSubmit(formData){
      this.query.keyword = formData.keyword;
      this.query.is_delete = formData.is_delete;
      carinfo_query(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleButtonClick(button,formData){
      if (button.action === "search") {
        this.handleFormSubmit(formData);
      } else if (button.action === "create") {
        this.addFormButton();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
